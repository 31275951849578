import axios from "axios";

import {
  ApiResponse,
  AccountantItemResponse,
} from "../interfaces/ApiResponses";
import { getYearFromLocalStorage } from "@/core/helpers/utils";

export default class AccountantService {
  static fetch(
    departmentCode: string,
    accountCode: string,
    detailCode: string | undefined = undefined,
  ): Promise<{
    data: ApiResponse<{
      current_page: number;
      data: AccountantItemResponse[];
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return axios({
      method: "GET",
      url: `${
        process.env.VUE_APP_HARACO_GATEWAY_API_URL
      }/tai-khoan/search?matk=${accountCode}${
        detailCode ? `&mact=${detailCode}` : ""
      } `,
      headers: {
        "X-Authorization": process.env.VUE_APP_API_KEY_1,
        year: getYearFromLocalStorage(),
        madonvi: departmentCode,
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  static find({
    ma_tk, ma_ct, ma_don_vi
  }: {
    ma_don_vi: string;
    ma_tk: string;
    ma_ct: string;
  }): Promise<any> {
    return axios({
      method: "GET",
      url: `${
        process.env.VUE_APP_HARACO_GATEWAY_API_URL
      }/tai-khoan/fetch?matk=${ma_tk}${
        ma_ct ? `&mact=${ma_ct}` : ""
      } `,
      headers: {
        "X-Authorization": process.env.VUE_APP_API_KEY_1,
        year: getYearFromLocalStorage(),
        madonvi: ma_don_vi,
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}

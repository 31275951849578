
import {defineComponent, ref} from 'vue';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {
  formatCurrencyAndQuantity,
  getErrorMsg,
  getHoiDongBbkn,
  setHoiDongBbkn,
  swalNotification,
} from '@/core/helpers/utils';
import {useLoading} from '@/core/hooks/use-loading';
import {
  IKhoResponseBase,
  IVatTuBienBan,
  IVatTuPhieuNhapKho
} from '@/core/interfaces/ApiResponses';
import {KhoService} from '@/core/services/Kho.service';
import {useCreatingForm} from '@/core/hooks/use-creating-form';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {UserService} from '@/core/services/UserService';
import {ElSelect} from 'element-plus';
import {IUpdateVatTuItemsPhieuLeRequestPayload, PhieuNhapKhoService} from '@/core/services/PhieuNhapKho.service';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {useButtonCustom} from '@/core/hooks/use-button-custom';
import {AddVatTuItemsFor} from '@/core/enums/addVatTuItemsFor.enum';
import {
  BienBanKiemNghiemService,
  IBienBanKiemNghiemWithPhieuLePayload
} from '@/core/services/BienBanKiemNghiem.service';

import useFormInput from '@/core/hooks/use-form-input';
import AddVatTuItems, {IVatTuDaChon} from '@/components/add-vat-tu-items/AddVatTuItems.vue'
import ButtonCustom from '../../../../../components/shared/buttons/ButtonCustom.vue';
import DivCol from '../../../../../layout/_shared/DivCol.vue';
import DivRow from '../../../../../layout/_shared/DivRow.vue';
import LabelCustom from '@/components/shared/label-custom/LabelCustom.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue'
import ToolService from '../../../../../core/services/Tool.service';
import VatTuBienBanTable
  from '@/views/crafted/pages/bien-ban-kiem-nghiem/vat-tu-bien-ban-kiem-nghiem/VatTuBienBanTable.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import TaiKhoanSelection from '@/components/shared/tai-khoan-selection/TaiKhoanSelection.vue';
import useTaiKhoan from '@/core/hooks/useTaiKhoan';

export default defineComponent({
  name: 'phieu-nhap-kho-le-form',

  components: {
    TaiKhoanSelection,
    ConfirmationDialog,
    AddVatTuItems,
    ButtonCustom,
    DivRow,
    DivCol,
    LabelCustom,
    PageRowLayout,
    VatTuBienBanTable,
  },

  setup() {
    const khoDetail = ref<IKhoResponseBase | null>(null);

    const {startLoading, endLoading} = useLoading();

    const {clearTaiKhoanData, tkData, onSelectTaiKhoan, setTkData} = useTaiKhoan();

    const {focusFirstErrInput} = useFormInput();

    const {isCreating, formRef} = useCreatingForm();

    const {goBack, replace} = useRouterCustom();

    const {moment} = useDateTime();

    const {ButtonsType, ButtonTypeColors} = useButtonCustom();

    const showConfirmDialog = ref(false);

    const nguoiNhanSelectInput = ref<null | typeof ElSelect>(null);

    const hoiDongKiemNghiemItems = [] as {
      thuTu: number,
      hoTen: string,
      chucVu: string,
      thanhPhan: string,
    }[];

    for (let i = 0; i <= 3; i++) {
      hoiDongKiemNghiemItems.push({
        thuTu: i + 1,
        hoTen: '',
        chucVu: '',
        thanhPhan: '',
      });
    }

    const hoiDongKiemNghiem = ref(getHoiDongBbkn().length ? getHoiDongBbkn() : hoiDongKiemNghiemItems);

    const rules = ref({
      giam_doc: {},
      ngay_bien_ban: [
        {
          required: true,
          message: "Phải nhập ngày",
          trigger: "blur",
        },
      ],
      y_kien: [
        {
          required: true,
          message: 'Không được để trống',
          trigger: 'blur',
        },
      ],
    });

    const form = ref({
      ngay_bien_ban: new Date(),
      giam_doc: '',
      so_hoa_don: '',
      y_kien: '',
    });

    return {
      khoDetail,
      startLoading, endLoading, moment,
      formRef, isCreating, form, rules, nguoiNhanSelectInput,
      goBack, replace,
      ButtonsType, ButtonTypeColors,
      AddVatTuItemsFor,
      hoiDongKiemNghiem,
      showConfirmDialog,
      focusFirstErrInput,
      clearTaiKhoanData, tkData, onSelectTaiKhoan, setTkData,
    }
  },

  data() {
    return {
      idKho: 0,
      idBienBan: 0,
      idPhieuNhap: 0,
      isAddVatTuModalDisplay: false,
      vatTuDangChonItems: [] as IVatTuPhieuNhapKho[],
      totalPriceInText: 'Không',
      danhSachNguoiNhanAvailable: [{
        label: 'Chưa chọn',
        value: 0
      }] as { label: string, value: number }[],
    }
  },

  async mounted() {
    setCurrentPageTitle('Kho');

    this.idKho = +this.$route.params.idKho;

    await this.startLoading();

    await this.fetchNguoiNhan();

    try {
      await this.fetchKho();

      if (!this.isCreating) {
        this.idPhieuNhap = +this.$route.params.idPhieuNhap;

        await this.fetchPhieuNhap();
      }

      await this.endLoading();
    } catch (e) {
      await this.endLoading();

      await swalNotification(
        getErrorMsg(e, 'Có lỗi khi lấy thông tin kho hoặc phiếu nhập'),
        'error',
      );
    }
  },

  computed: {
    totalPriceInNumber() {
      let totalInNumber = 0;

      if (this.vatTuDangChonItems.length === 0) {
        return 0;
      } else {
        this.vatTuDangChonItems.forEach(item => {
          totalInNumber += Math.round(item.pivot.don_gia * item.pivot.so_luong)
        });

        return totalInNumber;
      }
    },
    updateVatTuOnly(): boolean {
      // TODO: update later cho phép update mọi data, tạm thời chỉ cho update vật tư
      return false;
    },
    vatTuItemsDisplay(): IVatTuBienBan[] {
      return this.vatTuDangChonItems.map((vatTuItem: IVatTuPhieuNhapKho) => ({
        ...vatTuItem,
        so_luong_trong_bien_ban: vatTuItem.pivot.so_luong,
        so_luong_can: 0,
        phuong_thuc: vatTuItem.pivot.phuong_thuc_kiem_nghiem,
        is_dat: true,
        pivot: {
          id_hop_dong: 0,
          is_dat: 1,
          id_vat_tu: vatTuItem.id,
          so_luong: +vatTuItem.pivot.so_luong,
          so_tien: +vatTuItem.pivot.don_gia,
        },
      }))
    },
  },

  watch: {
    async totalPriceInNumber(value) {
      if (!value) {
        this.totalPriceInText = 'Không';
      } else {
        const {
          data: {data},
        } = await ToolService.convertPriceToText(value);

        this.totalPriceInText = data;
      }
    }
  },

  methods: {
    formatCurrencyAndQuantity,

    buildRequestPayloadParams(): IBienBanKiemNghiemWithPhieuLePayload | IUpdateVatTuItemsPhieuLeRequestPayload {
      if (this.updateVatTuOnly) return {
        id: this.idPhieuNhap,
        vat_tu_items: this.vatTuDangChonItems.map((vatTu: IVatTuPhieuNhapKho) => ({
          id: vatTu.id,
          so_luong: +vatTu.pivot.so_luong,
          don_gia: +vatTu.pivot.don_gia,
          phuong_thuc_kiem_nghiem: vatTu.pivot.phuong_thuc_kiem_nghiem,
        })),
      }

      return {
        ...(!this.isCreating && this.idPhieuNhap && this.idBienBan && {
          id_bien_ban: this.idBienBan,
        }),
        ten_giam_doc: this.form.giam_doc,
        ngay_bien_ban: this.moment(this.form.ngay_bien_ban).format('YYYY-MM-DD'),
        thanh_phan_hoi_dong: this.hoiDongKiemNghiem,
        id_kho: this.idKho,
        y_kien: this.form.y_kien,
        matk_co: this.tkData.maTaiKhoan,
        ...(this.tkData.maChiTiet && {
          mact_co: this.tkData.maChiTiet,
        }),
        ...(this.form.so_hoa_don && {
          so_hoa_don: this.form.so_hoa_don,
        }),
        tentk_co: this.tkData.tenTaiKhoan,
        vat_tu_items: this.vatTuDangChonItems.map((vatTu: IVatTuPhieuNhapKho) => ({
          id: vatTu.id,
          so_luong: +vatTu.pivot.so_luong,
          don_gia: +vatTu.pivot.don_gia,
          ket_qua_kiem_nghiem: 1,
          phuong_thuc_kiem_nghiem: vatTu.pivot.phuong_thuc_kiem_nghiem,
        })),
      }
    },

    async fetchKho() {
      const {data: {data}} = await KhoService.fetchWarehouseDetailById(this.idKho.toString());
      this.khoDetail = data;
    },

    async fetchPhieuNhap() {
      const {
        data: {
          data: {
            bien_ban_kiem_nghiem,
            ngay_hoa_don,
            nguoi_giao,
            so_hoa_don,
            ma_ct_co,
            ma_tk_co,
            vat_tu_nhap_kho,
            ten_tk_co,
          },
        },
      } = await PhieuNhapKhoService.get(this.idPhieuNhap);

      this.hoiDongKiemNghiem = bien_ban_kiem_nghiem?.thanh_phan_hoi_dong
        ? JSON.parse(bien_ban_kiem_nghiem?.thanh_phan_hoi_dong)
        : [];

      this.form.ngayLamHoaDon = new Date(ngay_hoa_don);

      this.form.giam_doc = nguoi_giao;

      this.form.so_hoa_don = so_hoa_don;

      this.form.y_kien = bien_ban_kiem_nghiem?.y_kien;

      if (bien_ban_kiem_nghiem?.ngay_bien_ban) {
        this.form.ngay_bien_ban = new Date(bien_ban_kiem_nghiem.ngay_bien_ban);
      }

      this.idBienBan = bien_ban_kiem_nghiem?.id ?? 0;

      this.setTkData(ma_tk_co, 'ma_tk');

      if (ma_ct_co) {
        this.setTkData(ma_ct_co, 'ma_ct');
      }

      this.setTkData(ten_tk_co, 'ten_tk')

      this.vatTuDangChonItems = vat_tu_nhap_kho.map((vatTu) => ({
        ...vatTu,
        don_gia: vatTu.pivot.don_gia,
        pivot: {
          ...vatTu.pivot,
          don_gia: vatTu.pivot.don_gia,
        },
      }));
    },

    async fetchNguoiNhan() {
      try {
        await this.startLoading();

        const {data: {data: {data}}} = await UserService.list();

        this.danhSachNguoiNhanAvailable = [
          ...this.danhSachNguoiNhanAvailable,
          ...data.map(user => ({
            value: user.id,
            label: user.ho_va_ten,
          }))
        ]
      } catch (e) {
        await swalNotification(
            getErrorMsg(e, 'Có lỗi xảy ra khi lấy thông tin người nhận'),
            'error',
        );

        await this.endLoading();
      }
    },

    onAddedVatTuItems({vatTuDangChon: items}: { vatTuDangChon: IVatTuDaChon[] }) {
      this.isAddVatTuModalDisplay = false;
      this.transformSelectingVatTuToVatTuPhieuNhap(items);
    },

    transformSelectingVatTuToVatTuPhieuNhap(items: IVatTuDaChon[]) {
      this.vatTuDangChonItems = items.map(item => ({
        ...item,
        pivot: {
          don_gia: item.don_gia,
          id_phieu_nhap_kho: 0,
          id_vat_tu: item.id,
          so_luong: +item.so_luong,
        }
      }));
    },

    changePhuongThuc({index, value}) {
      this.vatTuDangChonItems[index].pivot.phuong_thuc_kiem_nghiem = value;
    },

    async onSubmit() {
      if (!this.tkData.maTaiKhoan || !this.tkData.tenTaiKhoan) {
        await this.focusFirstErrInput('tai-khoan-selection_tai-khoan');

        await swalNotification(
          'Bắt buộc phải nhập thông tin tài khoản',
          'error',
        );

        return;
      }

      if (!this.formRef) {
        return;
      }

      /* Cho phép đơn giá bằng không.
        if (this.totalPriceInNumber === 0) {
          await swalNotification(
            'Bắt buộc phải nhập vật tư',
            'error',
          );
          return;
        }
       */

      this.formRef.validate(async (valid: boolean) => {
        if (valid) {
          this.showConfirmDialog = true;

          return;
        }

        return false;
      });
    },

    onAddNguoiKiemNghiem() {
      const newThuTu = this.hoiDongKiemNghiem.length + 1;

      this.hoiDongKiemNghiem.push({
        thuTu: newThuTu,
        hoTen: '',
        chucVu: '',
        thanhPhan: '',
      })
    },

    onDeleteNguoiKiemNghiem() {
      this.hoiDongKiemNghiem.pop();
    },

    async sendRequest() {
      this.showConfirmDialog = false;

      setHoiDongBbkn(this.hoiDongKiemNghiem);

      try {
        await this.startLoading();

        if (this.updateVatTuOnly) {
          await PhieuNhapKhoService.updateVatTuItemsPhieuNhapLe(this.buildRequestPayloadParams());

          await this.endLoading();

          await swalNotification('Chỉnh sửa phiếu nhập thành công');

          await this.$router.replace({
            name: 'chi-tiet-phieu-nhap-kho',
            params: {
              id: this.idPhieuNhap,
            },
          });

          return;
        }

        const {
          data: {
            data: bienBanRes,
          },
        } = await BienBanKiemNghiemService.createOrUpdateBienBanWithPhieuLe(this.buildRequestPayloadParams());

        await this.endLoading();

        await swalNotification(`${this.isCreating ? 'Tạo mới' : 'Chỉnh sửa'} phiếu nhập thành công`);

        await this.$router.replace({
          name: 'chi-tiet-phieu-nhap-kho',
          params: {
            id: bienBanRes.phieu_nhap_kho.id,
          },
        });
      } catch (e) {
        await this.endLoading();

        await swalNotification(
            getErrorMsg(e,
              `Có lỗi xảy ra khi ${this.isCreating ? 'tạo mới' : 'chỉnh sửa'} phiếu nhập`,
            ),
            'error',
        );
        return;
      }
    }
  }
});

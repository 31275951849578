import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "mb-0",
  style: {"word-break":"break-word !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, null, {
    header: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.headerTitle), 1)
    ]),
    default: _withCtx((scope) => [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.classes),
        style: {"word-break":"break-word !important"}
      }, _toDisplayString(_ctx.get(scope.row, _ctx.property) || '-'), 3)
    ]),
    _: 1
  }))
}
import {
  ApiResponse, IDanhMucToaXeResponseBase, PaginationResponseBase,
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from "@/core/helpers/utils";

export default class DanhMucToaXeService {
  static list(
    current_page = 1,
    per_page = 200,
    searchTerm = '',
  ): Promise<{
    data: ApiResponse<PaginationResponseBase<IDanhMucToaXeResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/danh-muc-toa-xe/danh-sach', {
        params: {
          page: current_page,
          ...(searchTerm && {
            s: searchTerm
          }),
        },
        headers: {
          perpage: per_page,
        }
      }
    )
  }

  static get(id: number): Promise<{
    data: ApiResponse<IDanhMucToaXeResponseBase>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/danh-muc-toa-xe/chi-tiet/${id}`
    );
  }

  static create(
    ten_danh_muc: string,
    mo_ta: string,
    id_cha: number,
  ): Promise<{
    data: ApiResponse<IDanhMucToaXeResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('danhMucToaXeInstance[ten_danh_muc]', ten_danh_muc);
    bodyFormData.append('danhMucToaXeInstance[mo_ta]', mo_ta);
    bodyFormData.append('danhMucToaXeInstance[id_cha]', id_cha.toString());

    return apiServiceInstance().vueInstance.axios.post(
      '/api/v1/danh-muc-toa-xe/tao-moi',
      bodyFormData
    );
  }

  static update(
    id: number,
    ten_danh_muc: string,
    mo_ta: string,
    id_cha: number,
  ): Promise<{
    data: ApiResponse<IDanhMucToaXeResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('danhMucToaXeInstance[ten_danh_muc]', ten_danh_muc);
    bodyFormData.append('danhMucToaXeInstance[mo_ta]', mo_ta);
    bodyFormData.append('danhMucToaXeInstance[id_cha]', id_cha.toString());
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-toa-xe/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static assignToaXeItems(
    id: number,
    toaXeIds: number[],
  ) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    toaXeIds.forEach(id => {
      bodyFormData.append('toaXeInstance[]', id.toString());
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-toa-xe/toa-xe/${id}`,
      bodyFormData,
    );
  }

  static assisgnVatTuToDinhMucCoDinh(
    id: number,
    vatTuItem: { id: number, so_luong: number }[],
  ) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    vatTuItem.forEach(item => {
      bodyFormData.append(`dinhMucCoDinh[${item.id}]`, item.so_luong.toString());
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-toa-xe/dinh-muc-co-dinh/${id}`,
      bodyFormData,
    );
  }

  static addVatTuVaoDinhMuc(
    id: number,
    items: {
      id: number,
      so_luong: number,
    }[]
  ) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    items.forEach(item => {
      bodyFormData.append(`dinhMucCoDinh[${item.id}]`, item.so_luong.toString());
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-toa-xe/dinh-muc-co-dinh/${id}`,
      bodyFormData
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(`/api/v1/danh-muc-toa-xe/xoa/${id}`);
  }
}

import { nextTick } from 'vue';

const useFormInput = () => {
  const focusFirstErrInput = (id: string) => {
    nextTick(() => {
      const firstErrInput = document.querySelector(`#${id}`);

      if (firstErrInput) (firstErrInput as HTMLInputElement).focus();
    });
  };

  const focusElement = (id: string) => {
    nextTick(() => {
      const element = document.querySelector(`#${id}`);

      if (element) {
        (element as HTMLInputElement).focus();
      }
    });
  };

  return {
    focusElement,
    focusFirstErrInput,
  };
};

export default useFormInput;

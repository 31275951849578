
import { defineComponent } from 'vue';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
	components: { ButtonCustom },

  name: "confirmation-dialog",

	setup() {
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		return {
			ButtonsType, ButtonTypeColors,
		};
	},

  emits: ["on-close-button-click", "on-confirm-button-click", 'on-close-icon-click'],

  props: {
    title: {
      type: String,
			required: true,
    },
    cancelText: {
      type: String,
      default: "Hủy bỏ",
    },
    confirmText: {
      type: String,
      default: "Xác nhận",
    },
    isDisplay: {
      type: Boolean,
      required: true,
    },
		width: {
			type: String,
			default: '50%',
		}
  },

	data() {
		return {
			isDialogDisplay: false,
		}
	},

	watch: {
		isDisplay: {
			handler() {
				this.isDialogDisplay = this.isDisplay;
			},
			deep: true,
		}
	},

  methods: {
    onCloseButtonClickedHandler() {
      this.$emit("on-close-button-click");
    },
    onConfirmButtonClickedHandler() {
      this.$emit("on-confirm-button-click");
    },
		onCloseIconClickedHandler() {
			this.$emit('on-close-icon-click');
		}
  },
});

import {
  ApiResponse,
  ContractSupplyItemResponse,
} from "../interfaces/ApiResponses";
import { apiServiceInstance } from "../helpers/utils";
import { ICreateHopDongVatTuItemsRequest } from '../interfaces/ApiRequest.interface';

export default class HopDongVatTuService {
  static createHopDongVatTuItem(
    hopDongId: number,
    id_vat_tu: number,
    so_luong: number,
    don_gia: number,
  ): Promise<{
    data: ApiResponse<ContractSupplyItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append(
      "hopDongVatTu[id_hop_dong]",
      hopDongId.toString()
    );
    bodyFormData.append("hopDongVatTu[id_vat_tu]", id_vat_tu.toString());
    bodyFormData.append("hopDongVatTu[so_luong]", so_luong.toString());
    bodyFormData.append("hopDongVatTu[so_tien]", don_gia.toString());
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/hop-dong-vat-tu/tao-moi",
      bodyFormData
    );
  }

  static deletecreateHopDongVatTuItem(id: number) {
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/hop-dong-vat-tu/xoa/${id}`
    );
  }

  static createHopDongVatTuItems({
    id_hop_dong,
    vatTuItems,
  }: ICreateHopDongVatTuItemsRequest) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();

    vatTuItems.forEach(({id_vat_tu, so_luong, don_gia}, index) => {
      bodyFormData.append(`hdvt[${index}][id_vat_tu]`, id_vat_tu.toString());
      bodyFormData.append(`hdvt[${index}][so_luong]`, so_luong.toString());
      bodyFormData.append(`hdvt[${index}][so_tien]`, don_gia.toString());
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/hop-dong/hop-dong-vat-tu/${id_hop_dong}`,
      bodyFormData
    );
  }
}

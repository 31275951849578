
import { defineComponent, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import { DonViService } from '@/core/services/DonVi.service';
import { getErrorMsg, swalNotification } from '@/core/helpers/utils';
import { AccountantItemResponse } from '@/core/interfaces/ApiResponses';
import { ElAutocomplete } from 'element-plus';

import AccountantService from '../../../core/services/Accountant.service';

export default defineComponent({
  name: 'tai-khoan-selection',

  emits: ['on-select-ma-tai-khoan', 'on-select-ma-chi-tiet', 'on-select', 'on-clear'],

  data() {
    return {
      tenTaiKhoan: '',
      tenTaiKhoanChiTiet: '',
      maTaiKhoanDangChon: 0,
      maChiTietDangChon: '',
      taiKhoanItems: [] as AccountantItemResponse[],
    }
  },

  props: {
    label: {
      type: String,
      default: "Thông tin tài khoản:",
    },
    labelClasses: {
      type: String,
      default: 'text-start'
    },
    gapClass: {
      type: String,
      default: 'mb-5',
    },
    id: {
      type: String,
      default: 'tai-khoan-selection',
    },
    inputsCols: {
      type: Number,
      default: 10,
    },
    isDisplaySelectedAccountBelow: {
      type: Boolean,
      default: true,
    },
    labelCols: {
      type: Number,
      default: 2,
    },
    maTaiKhoanProp: {
      type: Number,
      default: 0,
    },
    maChiTietProp: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  setup() {
    const store = useStore();
    const ma_don_vi = ref('');
    const maChiTietInputRef = ref<null | typeof ElAutocomplete>(null);

    return {
      store,
      ma_don_vi,
      maChiTietInputRef,
    }
  },

  async mounted() {
    try {
      const { data: { data: { ma_don_vi } } } = await DonViService.fetchCurrentDepartmentById(
        this.store.getters.currentUser.departmentId
      );

      this.ma_don_vi = ma_don_vi;
    } catch (error) {
      swalNotification(
        getErrorMsg(error, 'có lỗi khi lấy thông tin chi tiết đơn vị'),
        'error',
      );
    }

    if (this.maTaiKhoanProp) {
      this.maTaiKhoanDangChon = this.maTaiKhoanProp;

      try {
        if (this.maChiTietProp) {
          this.maChiTietDangChon = this.maChiTietProp;

          const {
            data: {
              data: {
                ten_tai_khoan
              },
            },
          } = await AccountantService.find({
            ma_don_vi: this.ma_don_vi,
            ma_tk: this.maTaiKhoanDangChon.toString(),
            ma_ct: this.maChiTietDangChon,
          })

          this.tenTaiKhoan = `${this.maTaiKhoanDangChon} / ${this.maChiTietDangChon} - ${ten_tai_khoan}`;
        } else {
          const {
            data: {
              data: {
                ten_tai_khoan
              },
            },
          } = await AccountantService.find({
            ma_don_vi: this.ma_don_vi,
            ma_tk: this.maTaiKhoanDangChon.toString(),
            ma_ct: ''
          });

          this.tenTaiKhoan = ten_tai_khoan;
          this.tenTaiKhoanChiTiet = ten_tai_khoan;
        }
      } catch (error) {
        await swalNotification(
          getErrorMsg(error, 'có lỗi khi lấy thông tin tài khoản'),
          'error',
        );
      }
    }
  },

  computed: {
    isInputTaiKhoanChiTietDisabled() {
      return !this.maTaiKhoanDangChon;
    }
  },

  watch: {
    async maChiTietProp(value) {
      const { data: { data: { ma_don_vi } } } = await DonViService.fetchCurrentDepartmentById(
        this.store.getters.currentUser.departmentId
      );

      this.ma_don_vi = ma_don_vi;

      if (value) {
        const res = await AccountantService.find({
          ma_don_vi: this.ma_don_vi,
          ma_tk: this.maTaiKhoanProp.toString(),
          ma_ct: value,
        });

        this.tenTaiKhoanChiTiet = `${this.maTaiKhoanProp} / ${value} - ${res.data.data.ten_tai_khoan}`;
      }
    },

    async maTaiKhoanProp(value) {
      const { data: { data: { ma_don_vi } } } = await DonViService.fetchCurrentDepartmentById(
        this.store.getters.currentUser.departmentId
      );

      this.ma_don_vi = ma_don_vi;

      if (value) {
        const { data: { data: { data: response } } } = await AccountantService.fetch(
          this.ma_don_vi,
          value.toString(),
        );

        this.tenTaiKhoan = `${value} - ${response[0].ten_tai_khoan}`;
      }
    }
  },

  methods: {
    iconClickedHandler (type: 'tai_khoan' | 'chi_tiet') {
      this.$emit('on-clear', type)
    },

    async queryTaiKhoan(queryString: string, cb) {
      const { data: { data: { data } } } = await AccountantService.fetch(
        this.ma_don_vi,
        queryString,
      );

      if (data.length === 0) {
        cb([{
          ten_tai_khoan: 'Không có dữ liệu -',
          ma_tai_khoan: '',
        }]);

        return;
      }

      const result = [...data].map(item => ({
        value: item.ma_tai_khoan,
        ...item,
      }));

      cb(result);
    },

    async queryThongTinChiTiet(queryString, cb) {
      const { data: { data: { data } } } = await AccountantService.fetch(
        this.ma_don_vi,
        this.maTaiKhoanDangChon.toString(),
        queryString,
      );

      if (data.length === 0) {
        cb([{
          ten_tai_khoan: 'Không có dữ liệu -',
          ma_tai_khoan: '',
        }]);

        return;
      }

      const result = [...data].map(item => ({
        value: item.ma_tai_khoan,
        ...item,
      }));

      cb(result);
    },

    onSelectTaiKhoan(value: AccountantItemResponse) {
      if (value.ma_tai_khoan === '') {
        return;
      }

      this.maTaiKhoanDangChon = +value.ma_tai_khoan;

      if (value.ten_tai_khoan === 'Không có dữ liệu -') {
        this.tenTaiKhoan = 'Chưa chọn';
      } else {
        this.tenTaiKhoan = `${value.ma_tai_khoan} - ${value.ten_tai_khoan}`;
      }

      nextTick(() => {
				if (this.maChiTietInputRef) {
					this.maChiTietInputRef.focus();
				}
			});

			if (this.maChiTietInputRef) {
				this.maChiTietInputRef.focus();
			}

      this.$emit('on-select', {
        taiKhoanItem: value,
      });
    },

    onSelectTaiKhoanChiTiet(value: AccountantItemResponse) {
      if (value.ma_tai_khoan === '') {
        return;
      }

      this.maChiTietDangChon = value.ma_chi_tiet || '';
      if (value.ten_tai_khoan === 'Không có dữ liệu -') {
        this.tenTaiKhoanChiTiet = 'Chưa chọn';
      } else {
        this.tenTaiKhoanChiTiet = `${value.ma_chi_tiet} - ${value.ten_tai_khoan}`;
      }

      this.$emit('on-select', {
        taiKhoanItem: value,
      });
    }
  }
})

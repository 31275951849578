
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DivRow',

  props: {
    additionalClass: {
      type: String,
      default: '',
    }
  }
})

import { computed, ref } from 'vue';
import { ITaiKhoanResponseBase } from '@/core/interfaces/ApiResponses';
import { useStore } from 'vuex';
import { DonViService } from '@/core/services/DonVi.service';
import AccountantService from '@/core/services/Accountant.service';

const useTaiKhoan = () => {
  const store = useStore();

  const ma_don_vi = ref('');

  const taiKhoanData = ref<{
    id: number;
    maTaiKhoan: string;
    maChiTiet?: string;
    tenTaiKhoan: string;
  }>({
    id: 0,
    maChiTiet: '',
    maTaiKhoan: '',
    tenTaiKhoan: '',
  });

  const onSelectTaiKhoan = ({
    taiKhoanItem: {
      id: id_tai_khoan,
      ma_tai_khoan,
      ma_chi_tiet,
      ten_tai_khoan,
    }
  }: {
    taiKhoanItem: ITaiKhoanResponseBase,
  }) => {
    taiKhoanData.value.maTaiKhoan = ma_tai_khoan;

    taiKhoanData.value.tenTaiKhoan = ten_tai_khoan;

    taiKhoanData.value.id = id_tai_khoan;

    if (ma_chi_tiet) {
      taiKhoanData.value.maChiTiet = ma_chi_tiet.toString();
    }
  }

  const clearTaiKhoanData = async (type: 'tai_khoan' | 'chi_tiet') => {
    taiKhoanData.value.maChiTiet = '';

    if (type === 'tai_khoan') {
      taiKhoanData.value.maTaiKhoan = '';

      taiKhoanData.value.id = 0;

      taiKhoanData.value.tenTaiKhoan = '0';

      return;
    }

    const { data: { data: { ma_don_vi } } } = await DonViService.fetchCurrentDepartmentById(
      store.getters.currentUser.departmentId
    );

    const { data: { data: { data } } } = await AccountantService.fetch(
      ma_don_vi,
      taiKhoanData.value.maTaiKhoan,
    );

    const taiKhoan = data.find(({ ma_tai_khoan }) => ma_tai_khoan === taiKhoanData.value.maTaiKhoan);

    if (taiKhoan) {
      taiKhoanData.value.id = taiKhoan.id;

      taiKhoanData.value.tenTaiKhoan = taiKhoan.ten_tai_khoan;
    }
  }

  const tkData = computed(() => taiKhoanData.value);

  const setTkData = (value, type: 'ma_tk' | 'ma_ct' | 'ten_tk') => {
    switch (type) {
      case "ten_tk":
        taiKhoanData.value.tenTaiKhoan = value;
        break;
      case "ma_ct":
        taiKhoanData.value.maChiTiet = value;
        break;
      case "ma_tk":
      default:
        taiKhoanData.value.maTaiKhoan = value;
    }
  }

  return {
    clearTaiKhoanData,
    onSelectTaiKhoan,
    setTkData,
    taiKhoanData,
    tkData,
  }
}

export default useTaiKhoan;


import { defineComponent } from 'vue';
import { get } from 'lodash'

export default defineComponent({
  name: 'word-break-column',

  props: {
    optionalClasses: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    property: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes() {
      return `mb-0 ${this.optionalClasses}`;
    },
  },

  methods: {
    get,
  }
})


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DivCol',

  props: {
    cols: {
      type: Number,
      default: 1,
    },

    additionalClass: {
      type: String,
      default: '',
    }
  }
});

import { IVatTuResponseBase } from '@/core/interfaces/ApiResponses';

const useVatTu = () => {
  const isValidItems = (vatTuArr: IVatTuResponseBase[]) => {
    return vatTuArr.map(({ is_valid }) => is_valid === 1)
  }

  const isTextDangerColumn = ({ row }: { row: IVatTuResponseBase }) => row?.is_valid === 0 ? 'text-danger' : '';

  const isValidLabel = (vatTu: IVatTuResponseBase) => {
    if (vatTu?.is_valid === 1) return 'Hợp lệ';

    return 'Chờ phê duyệt';
  }

  return {
    isTextDangerColumn,
    isValidItems,
    isValidLabel,
  };
}

export default useVatTu;


import {defineComponent, PropType, ref, nextTick} from 'vue';
import {
	IDinhMucCoDinhResponseBase,
	IVatTuResponseBase,
	IVatTuHopDongResponseBase,
  IVatTuPhieuNhapKho,
  IVatTuTrongKhoResponseBase
} from '@/core/interfaces/ApiResponses';
import {
	formatCurrencyAndQuantity,
	swalNotification,
	instanceOfIDinhMucCoDinhResponseBase,
	instanceOfIVatTuTrongKhoResponseBase,
  instanceOfIVatTuPhieuNhapKhoResponseBase,
  getErrorMsg,
} from '@/core/helpers/utils';
import {ElInput} from 'element-plus';
import { differenceWith } from 'lodash';
import { VatTuService } from '@/core/services/VatTu.service';
import { AddVatTuItemsFor } from '@/core/enums/addVatTuItemsFor.enum';
import { useAccount } from '@/core/hooks/use-account';
import { KhoService } from '@/core/services/Kho.service';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import DanhMucToaXeService from '@/core/services/DanhMucToaXe.service';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import CurrencyInputCustom from '../forms-elements/CurrencyInputCustom.vue';
import HopDongVatTuService from '@/core/services/HopDongVatTu.service'
import WordBreakColumn from '../shared/work-break-column/WordBreakColumn.vue';
import useVatTu from '@/core/hooks/use-vat-tu';

export interface IVatTuDaChon extends IVatTuResponseBase {
	so_luong: number;
}

export default defineComponent({
	name: 'add-vat-tu-items',

	components: { ButtonCustom, CurrencyInputCustom, WordBreakColumn },

	emits: ['close', 'added'],

	props: {
		vatTuDaChonItems: {
			type: Array as PropType<Array<IDinhMucCoDinhResponseBase | IVatTuHopDongResponseBase | IVatTuPhieuNhapKho | IVatTuTrongKhoResponseBase>>,
			default: () => ([]),
		},
		danhMucToaXeId: {
			type: Number,
			default: 0,
		},
		hopDongId: {
			type: Number,
			default: 0,
		},
		khoId: {
			type: Number,
			default: 0,
		},
		isModalOpen: {
			type: Boolean,
			required: true,
		},
		useFor: {
			type: String as PropType<AddVatTuItemsFor>,
			default: AddVatTuItemsFor.DINH_MUC,
		},
	},

	setup() {
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		const { currentUserData } = useAccount();

		const vatTuInput = ref<null | typeof ElInput>(null);

		const danhMucVatTuInput = ref<null | typeof ElInput>(null);

		const soLuongInput = ref<null | typeof ElInput>(null);

    const { isTextDangerColumn, isValidLabel } = useVatTu();

		return {
			vatTuInput,
			danhMucVatTuInput,
			soLuongInput,
			currentUserData,
			ButtonsType,
      ButtonTypeColors,
      isTextDangerColumn,
      isValidLabel,
		}
	},

	watch: {
		isModalOpen(value) {
			this.isOpen = value;
			nextTick(() => {
				if (this.danhMucVatTuInput) {
					this.danhMucVatTuInput.focus();
				}
			})
			if (this.danhMucVatTuInput) {
				this.danhMucVatTuInput.focus();
			}
		},

		tenVatTu(value) {
			if (!value) {
				this.isVatTuItemsAvailable = true;
			}
		},

		danhMucVatTuId(id) {
			if (id === 0) {
				this.tenDanhMucVatTu = '';
				return;
			}
		},

		vatTuDaChonItems: {
			handler(items) {
				this.selectedVatTuItems = items.map(item => ({
					...item,
					so_luong: instanceOfIDinhMucCoDinhResponseBase(item)
						? item.pivot.so_luong
						: item.so_luong_trong_hop_dong,
				}))
			},
			deep: true,
		}
	},

	data() {
		return {
			AddVatTuItemsFor,
			isOpen: false,
			danhMucVatTuId: 0,
			vatTuId: 0,
			tenDanhMucVatTu: '',
			tenVatTu: '',
			isDanhMucItemsAvailable: true,
			isVatTuItemsAvailable: true,
			so_luong: 1,
			don_gia: 20000,
			selectableVatTuItems: [] as IVatTuResponseBase[],
			selectedVatTuItems: this.vatTuDaChonItems.map(item => ({
				...item,
				so_luong: instanceOfIDinhMucCoDinhResponseBase(item)
					? item.pivot.so_luong
					: instanceOfIVatTuPhieuNhapKhoResponseBase(item)
						? item.pivot.so_luong
						: instanceOfIVatTuTrongKhoResponseBase(item)
							? item.pivot.so_luong
							: item.so_luong_trong_hop_dong,
			})) as IVatTuDaChon[]
		}
	},

	methods: {
		formatCurrencyAndQuantity,

		onClose() {
			this.isOpen = false;
			this.$emit('close');
		},

		onSelectDanhMucVatTu({ id }) {
			this.danhMucVatTuId = id;

			if (this.vatTuInput) {
				this.vatTuInput.focus();
			}
		},

		onSelectVatTu({ id }) {
			this.vatTuId = id;

			if (this.soLuongInput) {
				this.soLuongInput.focus();
			}
		},

		async queryDanhMucVatTu(queryString: string, fn) {
			const { data: { data: { data } } } = await DanhMucVatTuService.searchByMaDanhMuc(queryString);

			if (data.length === 0 && queryString) {
				this.isDanhMucItemsAvailable = false;
			} else if (data.length >= 0) {
				this.isDanhMucItemsAvailable = true;
			}

			fn(data);
		},

		async queryVatTu(queryString: string, fn) {
			const { data: { data: { data: res } } } = await VatTuService.list({
				id_dm_vat_tu: this.danhMucVatTuId,
				s: queryString,
			})
			const result = differenceWith(res, this.selectedVatTuItems, (newItem, selectedItem: { id: number }) => {
				return newItem.id === selectedItem.id;
			});

			if (queryString && result.length === 0) {
				this.isVatTuItemsAvailable = false;
			} else if (result.length > 0) {
				this.isVatTuItemsAvailable = true;
			}
			fn(result);
		},

		async onAddVatTu() {
			const {
        data: {
          data: selectingVatTu,
        },
      } = await VatTuService.fetchSupplyDetail(this.vatTuId);

      await nextTick();

			if (selectingVatTu) {
				this.selectedVatTuItems.push({
					...selectingVatTu,
					so_luong: this.so_luong,
					don_gia: this.isForHopDong || this.isForPhieuNhapKho ? this.don_gia : selectingVatTu.don_gia,
				});

				if (this.danhMucVatTuInput) {
					this.danhMucVatTuInput.focus();
				}

				this.tenVatTu = '';

				this.vatTuId = 0;

				this.so_luong = 1;

				this.don_gia = 20000;

				this.danhMucVatTuId = 0;
			}
		},

		onDeleteVatTu(id) {
			this.selectedVatTuItems = this.selectedVatTuItems.filter(item => item.id !== id);
		},

		async submit() {
			const params = this.selectedVatTuItems.map(item => ({
				id: item.id,
				so_luong: item.so_luong,
			}));

			try {
				if (this.useFor === AddVatTuItemsFor.KHO) {
					try {
						await KhoService.assignVatTuItemVaoKho({
							id_kho: this.khoId,
							vat_tu_items: this.selectedVatTuItems.map(({ id, so_luong }) => ({
								id,
								so_luong
							})),
						});

						this.danhMucVatTuId = 0;
						this.vatTuId = 0;
						this.tenDanhMucVatTu = '';
						this.so_luong = 1;
						this.don_gia = 20000;
						this.$emit('added');
					} catch (e) {
						this.isOpen = false;
						await swalNotification(
							getErrorMsg(e, 'có lỗi khi lấy thông tin chi tiết đơn vị'),
							'error',
						);
						this.$emit('added');
					}
				}

				if (this.useFor === AddVatTuItemsFor.DINH_MUC) {
					await DanhMucToaXeService.addVatTuVaoDinhMuc(
						this.danhMucToaXeId,
						params
					);
					this.danhMucVatTuId = 0;
					this.vatTuId = 0;
					this.tenDanhMucVatTu = '';
					this.so_luong = 1;
					this.don_gia = 20000;
					this.$emit('added');
				}

				if (this.useFor === AddVatTuItemsFor.HOP_DONG) {
					await HopDongVatTuService.createHopDongVatTuItems({
						id_hop_dong: this.hopDongId,
						vatTuItems: this.selectedVatTuItems.map(vatTu => ({
							don_gia: vatTu.don_gia,
							id_vat_tu: vatTu.id,
							so_luong: vatTu.so_luong,
						})),
					})
					this.danhMucVatTuId = 0;
					this.vatTuId = 0;
					this.tenDanhMucVatTu = '';
					this.so_luong = 1;
					this.don_gia = 20000;
					this.$emit('added');
				}

				if (this.useFor === AddVatTuItemsFor.PHIEU_NHAP_LE) {
					this.$emit('added', {
						vatTuDangChon: [...this.selectedVatTuItems]
					});
				}
			} catch (e) {
				await swalNotification(
					'Có lỗi xảy ra khi thêm vật tư',
					'error'
				);
				this.danhMucVatTuId = 0;
				this.vatTuId = 0;
				this.tenDanhMucVatTu = '';
				this.so_luong = 1;
				this.$emit('close');
			}
		}
	},

	computed: {
		isForAddVatTuKho() {
			return this.useFor === AddVatTuItemsFor.KHO;
		},

		isForHopDong() {
			return this.useFor === AddVatTuItemsFor.HOP_DONG;
		},

		isForPhieuNhapKho() {
			return this.useFor === AddVatTuItemsFor.PHIEU_NHAP_LE;
		},

		titleDisplay() {
			return this.useFor === AddVatTuItemsFor.HOP_DONG
				? 'Thêm vật tư vào hợp đồng'
				: this.useFor === AddVatTuItemsFor.PHIEU_NHAP_LE
					? 'Thêm vật tư vào phiếu nhập'
					: this.useFor === AddVatTuItemsFor.KHO
						? 'Thêm vật tư vào kho'
						: 'Thêm vật tư vào định mức';
		},

		isDonGiaInputDisabled() {
			return this.vatTuId === 0 || this.so_luong === 0;
		},

		isAddButtonDisable() {
			return this.vatTuId === 0 || this.so_luong <= 0 || isNaN(this.so_luong);
		},

		isChange() {
			if (this.vatTuDaChonItems?.length !== this.selectedVatTuItems.length) {
				return true;
			}

			const compFuc = (firstItem, secondItem) => {
				return firstItem.id === secondItem.id;
			}

			return differenceWith(this.vatTuDaChonItems, this.selectedVatTuItems, compFuc).length > 0 ||
				differenceWith(this.selectedVatTuItems, this.vatTuDaChonItems, compFuc).length > 0;
		},

		emptyText() {
			if (this.vatTuDaChonItems?.length === 0) {
				return 'Chưa có vật tư nào được chọn'
			}
			return 'Bạn đã xóa tất cả vật tư';
		}
	}
})

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(`row ${_ctx.gapClass}`)
    }, [
      _createElementVNode("label", {
        class: _normalizeClass([
      `col-${_ctx.labelCols} d-flex align-items-center fw-bold`,
      _ctx.labelClasses])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ required: !_ctx.readOnly })
        }, _toDisplayString(_ctx.label), 3)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([`col-${_ctx.inputsCols}`])
      }, [
        _createVNode(_component_el_autocomplete, {
          id: `${_ctx.id}_tai-khoan`,
          class: "w-100",
          clearable: "",
          disabled: _ctx.readOnly,
          placeholder: _ctx.readOnly ? '' : 'tìm kiếm',
          "value-key": "ten_tai_khoan",
          modelValue: _ctx.tenTaiKhoan,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tenTaiKhoan) = $event)),
          "trigger-on-focus": true,
          "fetch-suggestions": _ctx.queryTaiKhoan,
          onSelect: _ctx.onSelectTaiKhoan,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.iconClickedHandler('tai_khoan')))
        }, {
          default: _withCtx(({ item }) => [
            _createElementVNode("span", null, [
              _createElementVNode("strong", null, _toDisplayString(item.ma_tai_khoan), 1)
            ]),
            _createElementVNode("span", null, " - " + _toDisplayString(item.ten_tai_khoan), 1)
          ]),
          _: 1
        }, 8, ["id", "disabled", "placeholder", "modelValue", "fetch-suggestions", "onSelect"])
      ], 2)
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([`col-${_ctx.labelCols}`])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass([`col-${_ctx.inputsCols}`])
      }, [
        _createVNode(_component_el_autocomplete, {
          id: `${_ctx.id}_chi-tiet`,
          class: "w-100",
          clearable: "",
          placeholder: _ctx.readOnly ? '' : 'tìm kiếm',
          ref: "maChiTietInputRef",
          modelValue: _ctx.tenTaiKhoanChiTiet,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tenTaiKhoanChiTiet) = $event)),
          "value-key": "ten_tai_khoan",
          "trigger-on-focus": false,
          "fetch-suggestions": _ctx.queryThongTinChiTiet,
          disabled: _ctx.isInputTaiKhoanChiTietDisabled || _ctx.readOnly,
          onSelect: _ctx.onSelectTaiKhoanChiTiet,
          onFocus: _cache[4] || (_cache[4] = ($event: any) => ($event.target.select())),
          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.iconClickedHandler('chi_tiet')))
        }, {
          default: _withCtx(({ item }) => [
            _createElementVNode("span", null, [
              _createElementVNode("strong", null, _toDisplayString(item.ma_tai_khoan) + " / " + _toDisplayString(item.ma_chi_tiet), 1)
            ]),
            _createElementVNode("span", null, " - " + _toDisplayString(item.ten_tai_khoan), 1)
          ]),
          _: 1
        }, 8, ["id", "placeholder", "modelValue", "fetch-suggestions", "disabled", "onSelect"])
      ], 2)
    ])
  ], 64))
}